import {
  SA_PROGRAM_CSV_FILE_UPLOAD,
  SA_PROGRAM_CSV_FILE_UPLOAD_SUCCESS,
  SA_PROGRAM_CSV_FILE_UPLOAD_FAIL,
  SA_PROGRAM_CSV_FILE_UPLOAD_RESET,
  SA_PROGRAM_CSV_FILE_SUBMIT,
  SA_PROGRAM_CSV_FILE_SUBMIT_SUCCESS,
  SA_PROGRAM_CSV_FILE_SUBMIT_FAIL,
} from "./UpdateSAProgramStatusActionType";
import {
  ISubmitCsvFileFail,
  ISubmitCsvFileSuccess,
  IUploadCsvFileFail,
  IUploadCsvFileSuccess,
  UpdateSAProgramActionTypes,
} from "./UpdateSAProgramStatusAction";
import { IUpdateSAProgramStatusState } from "./IUpdateSAProgramStatusState";

const INITIAL_STATE: IUpdateSAProgramStatusState = {
  uploadCSVFileRequest: {
    CsvBase64: "",
  },
  submitCSVFileRequest: {
    fileId: "",
  },
  isUploading: false,
  errorMessage: "",
  validationResponse: {
    isValid: false,
    fileId: "",
    errors: [],
    summary: [],
  },
  submitResponse: {
    submitted: false,
  },
};

/**
 * Updates state of fetch upload Csv File.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
const applyUploadCsv = (state: IUpdateSAProgramStatusState): IUpdateSAProgramStatusState => ({
  ...state,
  uploadCSVFileRequest: {
    ...state.uploadCSVFileRequest,
  },
  isUploading: true,
  errorMessage: "",
});

/**
 * Updates state of fetch upload Csv File success.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @param {IUploadCsvFileSuccess} action The action state.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
const applyUploadCsvSuccess = (
  state: IUpdateSAProgramStatusState,
  action: IUploadCsvFileSuccess
): IUpdateSAProgramStatusState => ({
  ...state,
  validationResponse: action.validationResponse,
  isUploading: false,
  errorMessage: "",
});

/**
 * Updates state of fetch upload Csv File fail.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @param {IUploadCsvFileFail} action The action state.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
const applyUploadCsvFail = (
  state: IUpdateSAProgramStatusState,
  action: IUploadCsvFileFail
): IUpdateSAProgramStatusState => ({
  ...state,
  isUploading: false,
  errorMessage: action.errorMessage,
});

const applyUploadCsvReset = (): IUpdateSAProgramStatusState => INITIAL_STATE;

/**
 * Updates state of fetch Submit Csv File.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
const applySubmitCsv = (state: IUpdateSAProgramStatusState): IUpdateSAProgramStatusState => ({
  ...state,
  submitCSVFileRequest: {
    ...state.submitCSVFileRequest,
  },
  isUploading: true,
  errorMessage: "",
});

/**
 * Updates state of fetch Submit Csv File success.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @param {ISubmitCsvFileSuccess} action The action state.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
const applySubmitCsvSuccess = (
  state: IUpdateSAProgramStatusState,
  action: ISubmitCsvFileSuccess
): IUpdateSAProgramStatusState => ({
  ...state,
  submitResponse: action.submitResponse,
  isUploading: false,
  errorMessage: "",
});

/**
 * Updates state of fetch Submit Csv File Fail.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @param {ISubmitCsvFileFail} action The action state.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
const applySubmitCsvFail = (
  state: IUpdateSAProgramStatusState,
  action: ISubmitCsvFileFail
): IUpdateSAProgramStatusState => ({
  ...state,
  isUploading: false,
  errorMessage: action.errorMessage,
});

/**
 * Reducer function for Activity component.
 * @param {IUpdateSAProgramStatusState} state The activity state.
 * @param {IUpdateSAProgramStatusActionType} action The action type.
 * @returns {IUpdateSAProgramStatusState} The activity state.
 */
function UpdateSAProgramStatusReducer(
  state = INITIAL_STATE,
  action: UpdateSAProgramActionTypes
): IUpdateSAProgramStatusState {
  switch (action.type) {
    case SA_PROGRAM_CSV_FILE_UPLOAD:
      return applyUploadCsv(state);
    case SA_PROGRAM_CSV_FILE_UPLOAD_SUCCESS:
      return applyUploadCsvSuccess(state, action);
    case SA_PROGRAM_CSV_FILE_UPLOAD_FAIL:
      return applyUploadCsvFail(state, action);
    case SA_PROGRAM_CSV_FILE_UPLOAD_RESET: {
      return applyUploadCsvReset();
    }
    case SA_PROGRAM_CSV_FILE_SUBMIT:
      return applySubmitCsv(state);
    case SA_PROGRAM_CSV_FILE_SUBMIT_SUCCESS:
      return applySubmitCsvSuccess(state, action);
    case SA_PROGRAM_CSV_FILE_SUBMIT_FAIL:
      return applySubmitCsvFail(state, action);
    default:
      return state;
  }
}

export default UpdateSAProgramStatusReducer;
